.waw-header {
    height: 5em;
    margin-top: 4em;
    margin-left: 1.2em;
}

#searchBox {
    width: 100%;
    border-radius: 3px;
    margin-left: 0.7em;
    box-shadow: 0 0 1px 0 rgba(119, 119, 119, 0.75);
    background-color: #ffffff;
}

.waw-userCard div {
    width: 2.7em;
    margin-left: auto;
}

.waw-userCard h3 {
    float: right;
    padding-top: 7px;
    font-weight: lighter;
}

#waw-logo {
    width: 6em;
    margin-left: -0.3em;
}
