/**
* Rangeslider
*/
.rangeslider {
	margin: 20px 0;
	position: relative;
	background: #e6e6e6;
	-ms-touch-action: none;
	touch-action: none;
}

.sliderTick {
	position: absolute;
	margin-top: -32px;
	height: 13px;
	width: 3px;
}

.sliderLeftTick {
	margin-left: 32%;
	background: #e6e6e6;
}

.sliderRightTick {
	margin-left: 70%;
	background: #e6e6e6;
}

.rangeslider,
.rangeslider .rangeslider__fill {
	display: block;
	background: #c1b8af;
}
.rangeslider .rangeslider__handle {
	background: #d25e15;
	cursor: pointer;
	display: inline-block;
	position: absolute;
	box-shadow: 0px 0px 1px #000000;
	z-index: 10;
}

.rangeslider .rangeslider__handle-tooltip {
	width: 40px;
	height: 40px;
	text-align: center;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.8);
	font-weight: normal;
	font-size: 14px;
	transition: all 100ms ease-in;
	border-radius: 4px;
	display: inline-block;
	color: white;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
	margin-top: 12px;
	display: inline-block;
	line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
}
/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
	height: 12px;
	border-radius: 10px;
}
.rangeslider-horizontal .rangeslider__fill {
	height: 100%;
	background-color: #d25e15;
	border-radius: 10px;
	top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
	width: 30px;
	height: 30px;
	border-radius: 30px;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
}
.rangeslider-horizontal .rangeslider__handle:after {
	position: absolute;
	border-radius: 50%;
	background-color: #d25e15;
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
	top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid rgba(0, 0, 0, 0.8);
	left: 50%;
	bottom: -8px;
	transform: translate3d(-50%, 0, 0);
}
/**
* Rangeslider - Labels
*/
.rangeslider__labels {
	position: relative;
	margin-left: -13px;
	margin-top: 12px;
}

.rangeslider__labels .rangeslider__label-item {
	position: absolute;
	font-size: 0.9vw;
	cursor: pointer;
	display: inline-block;
}

@media only screen and (max-width: 700px) {
	.rangeslider {
		margin: 0 !important;
	}

	.sliderTick {
		position: absolute;
		margin-top: -12px;
		height: 13px;
		width: 3px;
	}

	.sliderLeftTick {
		margin-left: 31%;
		background: #e6e6e6;
	}

	.sliderRightTick {
		margin-left: 66%;
		background: #e6e6e6;
	}
}
