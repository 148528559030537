.tableOptions {
    height: 4em;
    padding-right: 2em;
}

.tableOptions a {
    margin-top: auto;
    margin-bottom: auto;
    height: 70%;
    float: right;
    width: 20%;
}
.tableOptions a p {
    margin-top: 1px;
}

.tableOptions a div {
    float: left;
}
