.detail-heading h1 {
	font-size: 2em;
}

hr {
	border-width: 1px 0px 0px 0px;
	border-color: #d2d8cd;
	background-color: #d2d8cd;
}

.waw-copy {
	padding-left: 0.5em;
}

.waw-copy:hover {
	cursor: pointer;
}

@media print {
	.waw-table-input {
		border: none;
		border-bottom: none;
	}

	.waw-bottom-fixed {
		display: none;
	}

	td {
		font-size: 10pt;
	}

	.ui.table thead {
		border: 2px solid;
		background-color: #ffffff;
		color: #000000;
		box-shadow: none;
	}
	.printOnly {
		display: inline;
		padding: 1em;
	}

	.no-spinners {
		-moz-appearance: textfield;
	}

	.no-spinners::-webkit-outer-spin-button,
	.no-spinners::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
