progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Removes Firefox border*/
    border: none;
    /* instead of background-color, IE uses color */
    color: #d25e15;
}

/* webkit */
progress::-webkit-progress-bar {
    background-color: #e3e3e3;
}

progress::-webkit-progress-value {
    background-color: #d25e15;
}

/* Firefox */
progress::-moz-progress-bar {
    background-color: #d25e15;
}
