.waw-menu {
    padding-top: 0;
    margin-top: 0;
}

.waw-menu-item h3 {
    margin-top: 7px;
}

.waw-menu-item div {
    float: left;
}
